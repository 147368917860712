<template>
  <ValidationProvider :name="label" :rules="rules" v-slot="{ errors }">
    <div class="input-field px-0"
         :class="{'block': isBlock}">
      <label class="select-label" :class="{'ps-3': isBlock}" v-if="isLabelShown && value">{{ placeholder }}</label>
      <v-select
          :clearable="clearable"
          :reduce="reduce"
          :value="value"
          :label="label"
          :placeholder="placeholder"
          :options="options"
          @input="onChangeValue"
          :disabled="disabled"
          :filterable="filterable"
          :noDrop="noDrop"
          :searchable="searchable"
          :loading="loading"
          :class="{'ps-3': isBlock}"
          @option:selected="$emit('optionSelected', $attrs['name'])"
          @search:blur="$emit('focusOut')"
          ref="vSelectComponent"
      >
        <template #no-options>
          {{ $t('noResultForSelect') }}
        </template>
        <template #spinner="{ loading }">
          <div
              v-if="loading"
              style="border-left-color: #2AA65C"
              class="vs__spinner"
          >
            The .vs__spinner class will hide the text for me.
          </div>
        </template>
        <template #open-indicator="{ attributes }" v-if="!loading && options.length > 1">
          <span v-bind="attributes" style="transition: .2s;" :class="{'pe-2': isBlock}"><icon icon-name="select-arrow-icon"></icon></span>
        </template>
      </v-select>
    </div>
    <!--    <span class="addition-text text-danger" v-if="errors.length > 0">{{errors ? errors[0]: ''}}</span>-->
    <span class="addition-text" v-if="addition">{{ addition }}</span>
  </ValidationProvider>
</template>

<script>

export default {
  name: "SearchSelect",
  props: {
    disabled: Boolean,
    filterable: {
      type: Boolean,
      default: true
    },
    noDrop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: option => option
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true
    },
    value: null,
    label: null,
    options: null,
    isBlock: Boolean,
    placeholder: null,
    isLabelShown: {
      type: Boolean,
      default: true,
    },
    rules: null,
    addition: null,
  },
  components: {
  },
  data() {
    return {
      // addition: null,
      // name: null,
      // rules: null
    }
  },
  computed:{

  },
  methods:{
    onChangeValue(value) {
      this.$emit('input', value);
      this.$emit('close-dropdown');
    }
  }
}
</script>

<style lang="scss">
.input-field {
}
.select-label {
  color: #99A3B3;
  font-size: 16px;
}
.vs__dropdown-toggle {
  border: none !important;
  border-radius: 0 !important;
}
.vs__selected-options {
  flex-wrap: unset !important;
}

.vs__selected-options, .vs__selected, .vs__search, .vs__search:focus {
  margin: 0 !important;
  padding: 0 !important;
}
.vs__selected {
  position: absolute !important;
  width: 100% !important;
}
input.vs__search::placeholder{
  color: #99A3B3 !important;
  font-size: 16px !important;
  font-family: inherit !important;
  font-weight: 500 !important;
}
.v-select, .v-select * {
  //padding: 0 !important;
  //margin: 0 !important;
}
.v-select {
  //padding-left: 16px;
}
.vs__dropdown-toggle {
  padding: 0 !important;
}
.vs__dropdown-menu {
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05) !important;
  //border-radius: 12px !important;
  border: unset !important;
  max-height: 200px !important;
}
.vs__dropdown-option--highlight {
  background: #2aa65c !important;
}
</style>
